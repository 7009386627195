import { PrismicDocument } from '@prismicio/types'
import * as prismic from '@prismicio/client'
import { orderBy } from 'lodash'

import { IAnyPage } from '../../types/prismic/pages'
import stockExchangeNews from '../../constants/stockExchangeNews/stockExchangeNews'
import { IInvestorInformationPage } from '../../types/prismic/pages/investorInformation'
import { IAnnualAccount } from '../../modules/investor-information/FinancialInformation/AnnualAccounts/AnnualAccountsOverview'

import PrismicClient from './Helpers'
import { getPrismicLanguage } from './RouteHelpers'
import { formatDocumentSlice } from './slice-helpers'

type DocFromPrismic = PrismicDocument<Record<string, any>, string, string>

export const cleanPage = (page: DocFromPrismic): IAnyPage => {
	return {
		lang: 'en',
		page: page.lang,
		// @ts-ignore  -  I am unsure how to norrow the type here
		type: page.type,
		...(true && { uid: page.uid }),
		id: page.id,
		alternate_languages: page.alternate_languages,
		data: {
			title: page.data.title,
			person_type:
				('person_type' in page.data && page.data.person_type) || null,
			link_to_parent:
				('link_to_parent' in page.data && page.data.link_to_parent) || null,
		},
	}
}

export const getAllByType = async <Type>(
	type: string,
	previewDataRef?: string,
	locale?: string,
	params?: Partial<Omit<prismic.BuildQueryURLArgs, 'page'>> | undefined
): Promise<any[]> => {
	const client = PrismicClient()
	return client
		.getAllByType(type, {
			ref: previewDataRef,
			lang: locale ? getPrismicLanguage(locale) : '*',
			...params,
		})
		.then((docs) => Promise.all(docs.map((doc) => formatDocumentSlice(doc))))
}

export const getSingleByType = async <Type>(
	type: string,
	previewDataRef?: string,
	locale?: string,
	deletedNews?: string[]
): Promise<Type & PrismicDocument<Record<string, any>, string, string>> => {
	const client = PrismicClient()
	return client
		.getSingle(
			// todo fix this ts
			type,
			{
				ref: previewDataRef,
				lang: locale ? getPrismicLanguage(locale) : '*',
			}
		)
		.then((doc) => formatDocumentSlice(doc, deletedNews))
}

export const getSingleByUid = async <Type>(
	type: string,
	uid: string,
	previewDataRef?: string,
	locale?: string
) => {
	const client = PrismicClient()
	return client
		.getByUID(type, uid, {
			ref: previewDataRef,
			lang: locale ? getPrismicLanguage(locale) : '*',
		})
		.then(formatDocumentSlice)
}

export const getAllByMultipleTypes = async (
	types: string[],
	previewDataRef?: string,
	locale?: string
): Promise<IAnyPage[]> => {
	return Promise.all(
		types.map((type) => getAllByType<IAnyPage>(type, previewDataRef, locale))
	).then((prismicDocs) => prismicDocs.flat())
}

export const getAllPages = async (
	previewDataRef?: string,
	locale?: string
): Promise<IAnyPage[]> => {
	const types = [
		'page',
		'newsoverview',
		'newsarticle',
		'financial_index_overview',
		'financial_index',
		'frontpage',
		'contact',
		'error_page',
		'stock_exchange_news',
		'investor_information',
		'persons',
		'singleperson',
		'document',
	]

	return getAllByMultipleTypes(types, previewDataRef, locale)
}

const getStockExchangeNewsFromJson = async (ticker, locale) => {
	const lang = locale === 'is' ? 'isl' : 'eng'
	return stockExchangeNews[`${ticker}${lang}`]
}

const getStockExchangeNews = async (ticker, locale) => {
	const lang = locale === 'is' ? 'isl' : 'eng'

	return fetch(
		`http://lmd.keldan.is/ir/news/${ticker}?lang=${lang}&start=0&limit=1000`
	)
}

export const getInvestorInformation = async (
	locale?: string,
	previewDataRef?: string
): Promise<{
	investorInformation: {
		annualAccounts: IAnnualAccount[]
		page: IInvestorInformationPage.IProps
		fundingContent: IInvestorInformationPage.IFundingContent[]
		financialInfoContent: IInvestorInformationPage.IFinancialInfoContent[]
		investorInformationSubpages: IInvestorInformationPage.IInvestorInformationSubpage[]
		investorMeetings: IInvestorInformationPage.IInvestorMeeting[]
	}
	stockExchangeNews: {
		kvika
		lykill
	}
}> => {
	const [
		annualAccounts,
		page,
		fundingContent,
		financialInfoContent,
		investorInformationSubpages,
		investorMeetings,
	] = await Promise.all([
		getAllByType<IAnnualAccount>('annual_accounts', previewDataRef, locale),
		getSingleByType<IInvestorInformationPage.IProps>(
			'investor_information',
			previewDataRef,
			locale
		),
		getAllByType<IInvestorInformationPage.IFundingContent>(
			'fundingcontent',
			previewDataRef,
			locale
		),
		getAllByType<IInvestorInformationPage.IFinancialInfoContent>(
			'financial_information',
			previewDataRef,
			locale
		),
		getAllByType<IInvestorInformationPage.IInvestorInformationSubpage>(
			'investor_information_subpage',
			previewDataRef,
			locale
		),
		getAllByType<IInvestorInformationPage.IInvestorMeeting>(
			'investor_meeting',
			previewDataRef,
			locale
		),
	])

	const [kvika, lykill] = await Promise.all([
		(await getStockExchangeNews('KVIKA', locale)).json(),
		await getStockExchangeNewsFromJson('Lykill', locale),
	])

	// * This function checks if a given string is a year
	// * This is a very simple check and should not be relied on for strict validation
	const checkIfYear = (string) => {
		if (string.length === 4 && Number.isInteger(Number(string))) {
			return true
		} else {
			return false
		}
	}

	/// Used for annual accounts and investor meetings
	const sortTabs = (annualAccounts) =>
		orderBy(
			annualAccounts,
			[(account) => !checkIfYear(account.uid), 'uid'],
			['asc', 'desc']
		)

	return {
		investorInformation: {
			annualAccounts: sortTabs(annualAccounts),
			page,
			fundingContent: orderBy(fundingContent, 'data.sort_order'),
			financialInfoContent: orderBy(financialInfoContent, 'data.sort_order'),
			investorInformationSubpages: orderBy(
				investorInformationSubpages,
				'data.sort_order'
			),
			investorMeetings: sortTabs(investorMeetings),
		},
		stockExchangeNews: {
			kvika,
			lykill,
		},
	}
}

// export const getDocumentsByIds = async (id: string[]) => {
// 	const client = PrismicClient()
// 	return await client.get({
// 		predicates: prismic.predicate.in('document.id', id),
// 	})
// }
