import algoliasearch from 'algoliasearch'
import { RichTextBlock } from 'prismic-reactjs'
import _ from 'lodash'

import { IPrismicImage } from '../types/prismic/baseTypes'
import {
	FinInfoSubpageContent,
	IAnyPage,
	IAnyPageExclError,
} from '../types/prismic/pages'
import { IAnySlice } from '../types/prismic/slices'
import { sanitizePreformattedHtmlTable } from '../components/Table/HtmlTable'
import { IAnnualAccount } from '../modules/investor-information/FinancialInformation/AnnualAccounts/AnnualAccountsOverview'
import { IInvestorInformationPage } from '../types/prismic/pages/investorInformation'
import { Translations } from '../locale/Translations'

import { replaceDoubleHyphen } from './general'
import { resolveDocPath } from './prismic/RouteHelpers'

const algoliaClient = algoliasearch(
	process.env.ALGOLIA_APP_ID as string,
	process.env.ALGOLIA_SEARCH_KEY as string
)

export interface IAlgoliaObject {
	objectID: string
	type: string
	title?: string
	description?: string
	image?: string
	isFeatured?: boolean
	slug: string
}

const firstValidString = (strings: (string | undefined)[] | undefined) =>
	strings
		? strings.find((str) => str !== undefined && str !== '' && str != null)
		: undefined

const firstValidImage = (images: (IPrismicImage | undefined)[]) => {
	return images.find((img) => img !== undefined && img.url !== undefined)
}

export const algoliaIndexName =
	process.env.VERCEL_ENV === 'development' ? 'test-kvika.is' : 'prod-kvika.is'

// A function to extract searchable text from a page and structure it for algolia.
export const formatPagesForSearch = (
	allPages: IAnyPageExclError[]
): IAlgoliaObject[] => {
	return allPages.flatMap((page) => {
		const { data, uid, lang } = page

		// ignoring typescript because we don't care if description does not exist on some types. Later we concat these strings and discard undefined values.
		const descArr = [
			//@ts-ignore
			data.description,
			//@ts-ignore
			data.subtitle,
			data.meta_description,
			data.og_description,
		].map(stringifyTextField)
		const titleArr = [data.title, data.meta_title, data.og_title].map(
			stringifyTextField
		)

		// ignoring typescript because we don't care if a specific property does not exist on every single type in IAnyPageExclError. We take the first valid image of the array.
		const img = firstValidImage([
			//@ts-ignore
			data.preview_image,
			//@ts-ignore
			data.image,
			//@ts-ignore
			data.headshot,
			//@ts-ignore
			data.portrait,
			data.og_image,
		])
		const algoliaImageUrl = (img && img.url) ?? ''

		return !data.no_index
			? [
					{
						objectID: `${lang}-page-${uid}`,
						type: 'page',
						title: `${replaceDoubleHyphen(firstValidString(titleArr) ?? '')}`,
						description: composeSearchString(descArr),
						image: algoliaImageUrl,
						isFeatured: true,
						slug: resolveDocPath(page, allPages) ?? '/#',
					},
			  ]
			: []
	})
}

export const getPageSliceResults = (
	allPages: IAnyPageExclError[]
): IAlgoliaObject[] => {
	// per Page results

	return _.compact(
		allPages.flatMap((page) => getSinglePageSliceResults(page, allPages))
	)
}

export const composeFinancialInfoSearchData = (
	investorInformation: {
		annualAccounts: IAnnualAccount[]
		page: IInvestorInformationPage.IProps
		fundingContent: IInvestorInformationPage.IFundingContent[]
		financialInfoContent: IInvestorInformationPage.IFinancialInfoContent[]
		investorInformationSubpages: IInvestorInformationPage.IInvestorInformationSubpage[]
		investorMeetings: IInvestorInformationPage.IInvestorMeeting[]
	},
	stockExchangeNews: {
		kvika
		lykill
	},
	allPages: IAnyPage[],
	locale: string = 'is'
): IAlgoliaObject[] => {
	const {
		annualAccounts,
		page,
		fundingContent,
		financialInfoContent,
		investorInformationSubpages,
		investorMeetings,
	} = investorInformation

	const { data, uid, lang } = page

	const slug = resolveDocPath(page, allPages) ?? '/#'

	const pageSearch = {
		objectID: `${lang}-page-${uid}`,
		type: 'page',
		title: `${replaceDoubleHyphen(data.title ?? '')}`,
		description: data.description,
		image: data.og_image?.url ?? '',
		isFeatured: true,
		slug,
	}

	const annualAccountsSearchData = annualAccounts.map((account) => ({
		objectID: `${lang}-page-${account.uid}`,
		type: 'page',
		title: `${replaceDoubleHyphen(account.uid)}`,
		description: account.data.report_description ?? '',
		image: data.og_image?.url ?? '',
		isFeatured: true,
		slug: slug + '?category=financialInformation&subCategory=0',
	}))

	const annualAccountSliceSearchData = annualAccounts.flatMap((account) =>
		account.data.body.map((slice, index) => ({
			objectID: `${account.lang}-slice-${account.uid}-${index}`,
			type: 'quarterly_overview',
			title: slice.primary.heading,
			description: slice.items.map((item) => item.document_title).join(', '),
			image: '',
			isFeatured: false,
			slug,
		}))
	)

	const shareholderCatalogueSearchData = {
		objectID: `${lang}-page-shareholderCatalogue`,
		type: 'page',
		title: Translations[locale].investorInformation.shareholderList,
		description:
			Translations[locale].shareholders.listDate +
			' ' +
			Translations[locale].shareholders.shareTypes,
		image: '',
		isFeatured: true,
		slug: slug + '?category=shareholderCatalog',
	}

	const composeFinancialInfoSubPageContent = (
		content:
			| IInvestorInformationPage.IFundingContent
			| IInvestorInformationPage.IFinancialInfoContent
			| IInvestorInformationPage.IInvestorInformationSubpage,
		category: string,
		subCategory?: string
	): IAlgoliaObject => ({
		objectID: `${lang}-page-${content.uid}`,
		type: 'page',
		title: `${replaceDoubleHyphen(content.data.document_name)}`,
		description: '',
		image: data.og_image?.url ?? '',
		isFeatured: true,
		slug:
			slug +
			`?category=${category}${
				subCategory ? '&subCategory=' + subCategory : ''
			}`,
	})

	const composeFinancialInfoSubPageSlice = (
		content:
			| IInvestorInformationPage.IFundingContent
			| IInvestorInformationPage.IFinancialInfoContent
			| IInvestorInformationPage.IInvestorInformationSubpage,
		sliceData: ISliceData,
		category: string,
		subCategory?: string,
		identifier?: string
	): IAlgoliaObject => {
		return {
			objectID: `${lang}-slice-${content.uid}-${identifier}`,
			type: sliceData.sliceType,
			title: sliceData.title ?? content.data.document_name,
			description: sliceData.description,
			image: '',
			isFeatured: false,
			slug:
				slug +
				`?category=${category}${
					subCategory ? '&subCategory=' + subCategory : ''
				}`,
		}
	}

	const mapFinancialInfoSubPageSlices = (
		content:
			| IInvestorInformationPage.IFundingContent
			| IInvestorInformationPage.IFinancialInfoContent
			| IInvestorInformationPage.IInvestorInformationSubpage,
		category: string,
		subCategory?: string
	): IAlgoliaObject[] => {
		const cleaned = _.compact([
			...(content.data.body ?? []),
			...(content.data.body1 ?? []),
			...(content.data.body2 ?? []),
			...(content.data.body3 ?? []),
			...(content.data.body4 ?? []),
		])
		return _.compact(
			cleaned.flatMap((slice, index) => {
				const searchData = getSliceData(slice, content)

				if (Array.isArray(searchData)) {
					return searchData.map((data) =>
						composeFinancialInfoSubPageSlice(
							content,
							data,
							category,
							subCategory,
							index.toString()
						)
					)
				} else if (searchData) {
					return composeFinancialInfoSubPageSlice(
						content,
						searchData,
						category,
						subCategory,
						index.toString()
					)
				} else return undefined
			})
		)
	}

	const financialInfoSearchData = financialInfoContent.flatMap(
		(content, index) => [
			composeFinancialInfoSubPageContent(
				content,
				'financialInformation',
				index.toString()
			),
			...mapFinancialInfoSubPageSlices(
				content,
				'financialInformation',
				index.toString()
			),
		]
	)
	const fundingContentSearchData = fundingContent.flatMap((content, index) => [
		composeFinancialInfoSubPageContent(content, 'funding', index.toString()),
		...mapFinancialInfoSubPageSlices(content, 'funding', index.toString()),
	])
	const investorInformationSubpagesSearchData =
		investorInformationSubpages.flatMap((content) => [
			composeFinancialInfoSubPageContent(content, content.data.document_name),
			...mapFinancialInfoSubPageSlices(content, 'investorInformation'),
		])

	const investorMeetingsSearchData = investorMeetings.map((meeting) => ({
		objectID: `${lang}-page-${meeting.uid}`,
		type: 'page',
		title: `${replaceDoubleHyphen(meeting.data.report_description)}`,
		description: meeting.data.documents
			.map((doc) => doc.document_title)
			.join(', '),
		image: meeting.data.report_image.url ?? '',
		isFeatured: true,
		slug: slug + '?category=investorMeetings',
	}))

	const investorMeetingsSliceSearchData = investorMeetings.flatMap((meeting) =>
		meeting.data.body.map((slice, index) => ({
			objectID: `${meeting.lang}-slice-${meeting.uid}-${index}`,
			type: 'investor_meeting',
			title: slice.primary.heading,
			description: slice.items.map((item) => item.document_title).join(', '),
			image: '',
			isFeatured: false,
			slug: slug + '?category=investorMeetings',
		}))
	)

	const mapNewsItem = (newsItem: any, ticker: string) => ({
		objectID: `${lang}-news-${ticker}-${newsItem.title}`,
		type: 'stockExchangeNewsItem',
		title: newsItem.title,
		description: '',
		image: '',
		isFeatured: false,
		slug: slug + `?category=stockExchangeNews&subCategory=${ticker}`,
	})

	const kvikaNewsSearchData = stockExchangeNews.kvika.items.map((newsItem) =>
		mapNewsItem(newsItem, 'kvika')
	)
	const lykillNewsSearchData = stockExchangeNews.lykill.items.map((newsItem) =>
		mapNewsItem(newsItem, 'lykill')
	)

	return [
		pageSearch,
		shareholderCatalogueSearchData,
		...annualAccountsSearchData,
		...annualAccountSliceSearchData,
		...financialInfoSearchData,
		...fundingContentSearchData,
		...investorInformationSubpagesSearchData,
		...investorMeetingsSearchData,
		...investorMeetingsSliceSearchData,
		...kvikaNewsSearchData,
		...lykillNewsSearchData,
	]
}

export const getSinglePageSliceResults = (
	page: IAnyPageExclError,
	allPages: IAnyPageExclError[]
): IAlgoliaObject[] | undefined => {
	if (!('body' in page.data)) return undefined

	const sliceData = page.data.body?.flatMap((slice) =>
		getSliceData(slice, page)
	)

	// const sliceData = getSliceData(page.data.body, page)
	const url = resolveDocPath(page, allPages, false) ?? '/#'

	let sliceCounter = 0
	const sliceResults = sliceData?.flatMap((slice) => {
		const slug = url + `#${slice && slice.sliceType}-${sliceCounter}`
		const objectSliceId = `${page.lang}-slice-${slug}`
		sliceCounter++

		return !page.data.no_index && slice
			? [
					{
						objectID: objectSliceId,
						type: slice.sliceType ?? 'Slice',
						title: slice.title ?? page.data.title,
						description: slice.description,
						image: '',
						isFeatured: false,
						slug: slug,
					},
			  ]
			: []
	})
	return sliceResults ?? []
}

const composeSearchString = (strings: (string | undefined)[]): string => {
	return _.compact(strings).join(' ')
}

const stringifyMultipleTextFields = (
	fields: (string | RichTextBlock[] | undefined)[]
): string[] => _.compact(fields.map(stringifyTextField))

// A function to extract the description for any kind of page description
const stringifyTextField = (
	field: string | RichTextBlock[] | undefined
): string | undefined => {
	switch (typeof field) {
		// Handles the case where the description is a string such as contact-us page
		case 'string':
			return field

		// Handles a generic page where description is a rich text.
		case 'object':
			if (Array.isArray(field) && field.length > 0) {
				return composeSearchString(field.map(({ text }) => text))
			} else {
				return ''
			}

		// Handles every kind of page that does not define a description.
		default:
			return undefined
	}
}

export const searchClient = (indexName) => ({
	search(requests: any) {
		algoliaClient.initIndex(indexName)
		if (
			requests.every(
				({ params }: { params: { query: string } }) => !params.query
			)
		) {
			return Promise.resolve({
				results: requests.map(() => ({
					hits: [],
					nbHits: 0,
					nbPages: 0,
					processingTimeMS: 0,
				})),
			})
		}
		const res = algoliaClient.search(requests)
		return res
	},
})

interface ISliceData {
	title?: string
	description?: string | undefined
	sliceType: string
	itemIndex?: number
}

// Used for debug purposes. You can log this to see where slices that are not implimented are coming from.
var unimplimentedSliceDict: {
	[key: string]: {
		sliceType: string
		numberOfInstances: number
		uidList: string[]
	}
} = {}

// Takes an array of slice and maps each into a format that algolia can search through. The only required field is sliceType,
// but atleast one of the other two should be defined otherwise there is notheing to search. If title is left empty it will be
// replaced with the page title. If description is undefined it will be an empty string in algolia.
//
// NOTE! Every time we create a new slice that should be searchable it needs to be added to this list.
const getSliceData = (
	slice: IAnySlice,
	page: IAnyPageExclError | FinInfoSubpageContent
): ISliceData | undefined | ISliceData[] => {
	const sliceType = slice.slice_type
	switch (sliceType) {
		case 'richtext':
		case 'shaded_richtext': {
			const title = slice.primary.title
			const searchableContent = stringifyTextField(slice.primary.content)

			return {
				title: title,
				description: searchableContent,
				sliceType,
			}
		}
		case 'richtext_cards': {
			return {
				description: stringifyMultipleTextFields(
					_.map(slice.items, 'content')
				).join(' '),
				sliceType,
			}
		}
		case 'link_card': {
			return {
				description: composeSearchString(
					slice.items.map((item) => item.card_title)
				),
				sliceType,
			}
		}
		case 'hero_carousel': {
			return {
				description: composeSearchString(
					slice.items.map((item) => stringifyTextField(item.text))
				),
				sliceType,
			}
		}
		case 'link_card1': {
			return {
				title: slice.primary.section_title,
				description: slice.items
					.map((item) => item.header + ' ' + item.description1)
					.join(' '),
				sliceType,
			}
		}
		case 'preformatted_html_table': {
			return {
				description: sanitizePreformattedHtmlTable(
					slice.primary.html_table[0].text
				),
				sliceType,
			}
		}
		case 'four_col_table_test': {
			const {
				column_1_heading,
				column_2_heading,
				column_3_heading,
				column_4_heading,
			} = slice.primary
			const headers = combineColumns([
				column_1_heading,
				column_2_heading,
				column_3_heading,
				column_4_heading,
			])
			const columns = slice.items
				.map(({ col1, col2, col3, col4 }) =>
					combineColumns(stringifyMultipleTextFields([col1, col2, col3, col4]))
				)
				.join(' ')
			return {
				title: slice.primary.table_title,
				description: headers + ' ' + columns,
				sliceType,
			}
		}
		case 'table__up_to_7_col_': {
			const {
				column_1_heading,
				column_2_heading,
				column_3_heading,
				column_4_heading,
				column_5_heading,
				column_6_heading,
				column_7_heading,
			} = slice.primary
			const headers = combineColumns([
				column_1_heading,
				column_2_heading,
				column_3_heading,
				column_4_heading,
				column_5_heading,
				column_6_heading,
				column_7_heading,
			])
			const columns = slice.items
				.map(({ col1, col2, col3, col4, col5, col6, col7 }) =>
					combineColumns(
						stringifyMultipleTextFields([
							col1,
							col2,
							col3,
							col4,
							col5,
							col6,
							col7,
						])
					)
				)
				.join()

			return {
				title: slice.primary.table_title,
				description: headers + ' ' + columns,
				sliceType,
			}
		}
		case 'highlight': {
			return {
				sliceType,
				description: stringifyTextField(slice.primary.description1),
			}
		}
		case 'column_content': {
			return {
				title: composeSearchString([
					slice.primary.section_title,
					stringifyTextField(slice.primary.content_heading),
				]),
				description: stringifyMultipleTextFields([
					slice.primary.first_column,
					slice.primary.second_column,
				]).join(' '),
				sliceType,
			}
		}
		case 'card_grid': {
			const ret = _.map(slice.items, (item) => {
				const title = [item.text[0]]
				const descriptions: RichTextBlock[] = item.text.slice(1)
				return {
					title: stringifyTextField(title),
					description: stringifyTextField(descriptions),
					sliceType,
				}
			})
			return ret
		}
		case 'link_list_description_columns': {
			return {
				description: slice.items
					.map((item) => _.compact([item.title1, item.description1]).join(' '))
					.join(' | '),
				sliceType,
			}
		}
		case 'hero_link_list': {
			return {
				description: slice.items.map((item) => item.link_text).join(' | '),
				sliceType,
			}
		}
		case 'subsidiaryinfo': {
			return {
				title: slice.primary.title1,
				description: stringifyTextField(slice.primary.description1),
				sliceType,
			}
		}
		case 'multi_section_description': {
			return {
				title: slice.primary.section_title,
				description: slice.items
					.map((item) => item.text_title + ' ' + item.text_description)
					.join(' '),
				sliceType,
			}
		}
		case 'notification': {
			return {
				description: stringifyTextField(slice.primary.text),
				sliceType,
			}
		}
		case 'halfimagedescription': {
			return {
				title: slice.primary.heading,
				description: stringifyTextField(
					slice.primary.description ?? slice.primary.heading ?? ''
				),
				sliceType,
			}
		}
		case 'single_row_title_and_description': {
			return {
				title: slice.primary.title1,
				description: stringifyTextField(slice.primary.description1),
				sliceType,
			}
		}
		case 'link_list': {
			return {
				title: slice.primary.link_list_title,
				description: composeSearchString(_.map(slice.items, 'link_title')),
				sliceType,
			}
		}
		case 'richtext___q_a': {
			return [
				{
					title: slice.primary.section_title,
					description: stringifyTextField(slice.primary.content),
					sliceType,
				},
				...slice.items.map((item) => ({
					title: '',
					description: '',
					sliceType,
				})),
			]
		}
		case 'organizationoperations': {
			return {
				title: slice.primary.title1,
				description: stringifyTextField(slice.primary.description1),
				sliceType,
			}
		}
		case 'heroimageribbon': {
			return {
				title: slice.primary.heading,
				description: slice.primary.description,
				sliceType,
			}
		}
		case 'two_column_list': {
			return {
				title: slice.primary.section_title,
				description: stringifyMultipleTextFields([
					slice.primary.first_column,
					slice.primary.second_column,
				]).join(' '),
				sliceType,
			}
		}
		case 'heroquote': {
			return {
				description: slice.primary.text,
				sliceType,
			}
		}
		case 'showcasing': {
			return {
				title: slice.primary.heading,
				description: stringifyTextField(slice.primary.description),
				sliceType,
			}
		}

		default: {
			//This console.log is good to remind devs to implement the slice for Search as well.
			if (process.env.VERCEL_ENV === 'development') {
				// console.log(
				// 	sliceType,
				// 	' has not been implemented in the search module!'
				// )
				if (
					unimplimentedSliceDict[sliceType] == null ||
					unimplimentedSliceDict[sliceType] == undefined
				) {
					unimplimentedSliceDict[sliceType] = {
						numberOfInstances: 1,
						sliceType,
						uidList: [page.uid ?? ''],
					}
				} else {
					unimplimentedSliceDict[sliceType].numberOfInstances += 1
					unimplimentedSliceDict[sliceType].uidList.push(page.uid ?? '')
				}
			}
			return undefined
		}
	}
}

const combineColumns = (arr: string[]): string => {
	return arr.join(' | ')
}
